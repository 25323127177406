<template>
  <div class="header">
    <el-menu :default-active="activeIndex" mode="horizontal">
      <el-menu-item index="1" style="font-size: 26px" @click="gotoHome">
        <img src="../../assets/images/logo.png" width="50px" alt="logo">
        
      </el-menu-item>
      <el-menu-item index="2" @click="gotoIndex">Main</el-menu-item>
      <el-menu-item v-if="!url.endsWith('index')" index="3" @click="gotoStep1">Step 1</el-menu-item>
      <el-menu-item v-if="!url.endsWith('index')" index="5" @click="gotoStep2">Step 2</el-menu-item>
      <el-menu-item v-if="!url.endsWith('index')" index="6" @click="gotoStep4">Step 4</el-menu-item>
      <el-menu-item v-if="!url.endsWith('index')" index="7" @click="gotoStep5">Step 5</el-menu-item>
      <el-menu-item v-if="!url.endsWith('index')" index="8" @click="gotoStep6">Step 6</el-menu-item>
      <el-submenu index="4" style="float: right" v-if="isLogin">
        <template slot="title">{{ userName }}</template>
        <!-- <el-menu-item index="4-1" class="big-item" @click="settings"></el-menu-item> -->
        <el-menu-item index="4-2" class="big-item" @click="logout">Log Out</el-menu-item>
      </el-submenu>
<!--      <i class="el-icon-bell news-link" v-if="isLogin" style="padding-top: 28px; font-size: 24px; float: right;" @click="openNews"></i>-->
      <i v-if="isLogin" class="el-icon-user" style="padding-top: 28px; font-size: 24px; float: right; cursor: pointer" @click="settings"></i>
      <div class="login-button">
        <el-button index="4" style="float: right" v-if="!isLogin" type="primary" @click="gotoLogin">Login</el-button>
      </div>
    </el-menu>
  </div>
</template>

<script>
  import user from "@/store/user";

  export default {
    name: 'pageHeader',
    data() {
      return {
        userName: '',
        activeIndex: '1',
        isLogin: false,
        url: window.location.href,
      };
    },
    created() {
      const userInfo = user.getters.getUser(user.state());
      if (userInfo)
      {
        this.isLogin = true;
        this.userName = userInfo.user.username;
      }
    },
    methods: {
      gotoHome: function () {
        this.$router.push('/index');
        location.reload();
      },
      gotoStep1: function () {
        if(this.$route.query.qid != undefined) {
          this.$router.push('/step1?qid='+this.$route.query.qid);
        }
        else {
          this.$router.push('/step1');
        }
      },
      gotoStep2: function () {
        if(this.$route.query.qid != undefined) {
          this.$router.push('/step2?qid='+this.$route.query.qid);
        }
        else {
          this.$router.push('/step2');
        }
      },
      gotoStep4: function () {
        if(this.$route.query.qid != undefined) {
          this.$router.push('/step4?qid='+this.$route.query.qid);
        }
        else {
          this.$router.push('/step4');
        }
      },
      gotoStep5: function () {
        if(this.$route.query.qid != undefined) {
          this.$router.push('/step5?qid='+this.$route.query.qid);
        }
        else {
          this.$router.push('/step5');
        }
      },
      gotoStep6: function () {
        if(this.$route.query.qid != undefined) {
          this.$router.push('/step6?qid='+this.$route.query.qid);
        }
        else {
          this.$router.push('/step6');
        }
      },
      gotoIndex: function () {
        const userInfo = user.getters.getUser(user.state());
        if (userInfo)
          this.$router.push('/index');
        else
          this.$router.push('/login');
      },
      gotoLogin() {
        this.$router.push('/login');
      },
      settings() {
        this.$router.push('/account');
      },
      logout() {
        this.$store.dispatch('clear');
        window.localStorage.removeItem('ova_admin_token')
        this.gotoLogin();
      }
    }
  }
</script>


<style>
.header .el-menu{
  height: 80px;
  padding-left: 50px;
  padding-right: 50px;
}

.header .el-menu--horizontal>.el-menu-item {
  float: left;
  height: 80px;
  line-height: 80px;
  margin: 0;
  border-bottom: 2px solid transparent;
  color: black;
}

.header .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid transparent;
  color: #409EFF !important;
}

.header .el-menu-item {
  font-size: 16px;
}

.header .el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 80px;
  line-height: 80px;
  color: black;
}

.header .el-submenu__title {
  font-size: 16px;
  color: black;
}

.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: none !important;
  color: black;
}

.big-item .el-menu-item li {
  line-height: 50px!important;
}

.login-button {
  padding: 20px;
}

.login-button button {
  width: 100px;
  background-color: #1687fd;
}

.news-link {
  cursor: pointer;
}
</style>