<template>
  <div>
    <div class="left-screen">
      <router-view name="left"></router-view>
    </div>
    <div class="right-screen">
      <router-view name="right"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SplitScreen',
};
</script>

<style>
.left-screen {
  float: left;
  width: 50%;
}

.right-screen {
  float: right;
  width: 50%;
}
</style>