<template>
  <el-row class="edit-header">
    <el-col :span="3" class="lf-bt">
      <el-popover
          width="0"
          trigger="hover">
        <el-button slot="reference" icon="el-icon-edit" @click="open_1">Name</el-button>
      </el-popover>
    </el-col>
    <el-col :span="4" class="lf-bt">
      <el-popover
          width="0"
          trigger="hover">
        <el-button slot="reference" icon="el-icon-edit-outline" @click="open_2">Description</el-button>
      </el-popover>
    </el-col>
    <el-col :span="4" class="lf-bt">
      <el-popover
          width="0"
          trigger="hover">
        <el-button slot="reference" icon="el-icon-edit-outline" @click="open_3">Industry</el-button>
      </el-popover>
    </el-col>
    <el-col :span="5" class="lf-bt">
      <el-popover
          width="0"
          trigger="hover">
        <el-button slot="reference" icon="el-icon-edit-outline" @click="open_4">Company Type</el-button>
      </el-popover>
    </el-col>
    <el-col :span="3" class="lf-bt">
      <el-popover
          width="0"
          trigger="hover">
        <el-button slot="reference" icon="el-icon-edit-outline" @click="open_5">Currency</el-button>
      </el-popover>
    </el-col>
    <!-- <el-col :span="2" class="lf-bt">
    <el-popover
        placement="top-start"
        title="高级设置"
        width="200"
        trigger="hover"
        content="可设置问卷回收截止时间，开启逻辑开关，报名问卷还能设置最大回收量">
      <el-button slot="reference" icon="el-icon-setting" @click="setting">高级设置</el-button>
    </el-popover>
    </el-col> -->
    <!-- <el-col :span="2" class="button" v-if="isReleased"><el-button type="success" icon="el-icon-share" @click="share">分享</el-button></el-col>
    <el-col :span="2" class="button" v-else><el-button type="success" icon="el-icon-upload2" @click="publish">发布</el-button></el-col> -->
    <el-col :span="4" class="button"><el-button type="warning" icon="el-icon-receiving" @click="save">Save</el-button></el-col>
    <!-- <el-col :span="2" class="button"><el-button type="primary" icon="el-icon-view" @click="preview">预览</el-button></el-col>
    <el-col :span="2" class="state true" v-if="isReleased"><i class="el-icon-finished"></i> 已发布</el-col>
    <el-col :span="2" class="state false" v-else>&emsp;<i class="el-icon-edit"> 未发布</i></el-col> -->
    <el-dialog :title="industryDialogTitle" :visible.sync="industryDialogVisible" class="dialog" >
      <el-form ref="form" label-width="100px">
        <el-form-item label="Industry" label-width="120px">
            <el-select v-model="industry" placeholder="Select Industry" @change="industryChange">
                <el-option
                    v-for="item in industryType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="industryDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="companyDialogTitle" :visible.sync="companyDialogVisible" class="dialog" >
      <el-form ref="form" label-width="100px">
        <el-form-item label="Company" label-width="120px">
            <el-select v-model="company" placeholder="Select Company Type" @change="companyChange">
                <el-option
                    v-for="item in companyType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="companyDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleConfirm2">Confirm</el-button>
      </span>
    </el-dialog>
  </el-row>
  
  <!-- <el-dialog :title="companyDialogTitle" :visible.sync="companyDialogVisible" class="dialog" >
  </el-dialog> -->
</template>

<script>
export default {
  name: "editHeader",
  props: {
    title: String,
    description: String,
    isReleased: Boolean,
  },
  data() {
    return {
      industry: '',
      company: '',
      industryType: [],
      industryDialogVisible: false,
      industryDialogTitle: 'Industry Select',
      companyType: [],
      companyDialogVisible: false,
      companyDialogTitle: 'Company Select',
    }
  },
  methods: {
    industryChange(value) {
        this.industry = value;
    },
    companyChange(value) {
        this.company = value;
    },
    handleConfirm(){
      this.industryDialogVisible = false
      if(this.industry != '') this.changeIndustry(this.industry);
    },
    handleConfirm2(){
      this.companyDialogVisible = false
      if(this.company != '') this.changeCompany(this.company);
    },
    open_1: function () {
      this.$prompt('Please input questionnaire name:', 'Name', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        inputPattern: /\S/,
        inputErrorMessage: 'Name cannot be empty'
      }).then(({ value }) => {
        this.changeTitle(value)
        this.$message({
          type: 'success',
          message: 'Name has been edited to: ' + value
        });
      }).catch(() => {
        
      });
    },
    open_2: function () {
      this.$prompt('Please input questionnaire description', 'Description', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then(({ value }) => {
        this.changeDescription(value)
        this.$message({
          type: 'success',
          message: 'Description has been edited'
        });
      }).catch(() => {
        
      });
    },
    open_3: function () {
      this.industryDialogVisible = true;
    },
    open_4: function () {
      this.companyDialogVisible = true;
    },
    open_5: function () {
      this.$prompt('Please input questionnaire currency', 'Currency', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        inputPattern: /\S/,
        inputErrorMessage: 'Questionnaire currency cannot be empty'
      }).then(({ value }) => {
        this.changeCurrency(value)
        this.$message({
          type: 'success',
          message: 'Currency has been edited'
        });
      }).catch(() => {
        
      });
    },
    setting: function () {
      this.$emit("advancedSetting");
    },
    publishSuccess:function (){
      this.$emit("publishSuccess");
    },
    changeTitle: function (value) {
      this.$emit("titleChanged", value);
    },
    changeDescription: function (value) {
      this.$emit("descriptionChanged", value);
    },
    changeIndustry: function (value) {
      this.$emit("industryChanged", value);
    },
    changeCompany: function (value) {
      this.$emit("companyChanged", value);
    },
    changeCurrency: function (value) {
      this.$emit("currencyChanged", value);
    },
    publish() {
      this.$emit("publishClicked");
    },
    share() {
      this.$emit("shareClicked");
    },
    save() {
      this.$emit("saveClicked");
    },
    preview: function () {
      this.$emit("qnPreview", '0');
    },
  },
  created() {
    this.$axios({
        method: 'get',
        url: 'https://api.oxvalue.cn:7999/api/question/v0.1/industry_admin/',
        headers:{
          'Authorization': window.localStorage.getItem('ova_admin_token')
        }
    })
    .then(res => {
      if (res.data['code'] == 403) {
        this.$store.dispatch('clear');
        window.localStorage.removeItem('ova_admin_token')
        this.$router.push('/login');
        return;
      }
      this.industryType = [];
      for(let i in res.data.data.industry_list){
        this.industryType.push({
          label: res.data.data.industry_list[i].id + '. ' + res.data.data.industry_list[i].name,
          value: res.data.data.industry_list[i].id,
        })
      }
    })
    this.$axios({
        method: 'get',
        url: 'https://api.oxvalue.cn:7999/api/question/v0.1/company_type_admin/',
        headers:{
          'Authorization': window.localStorage.getItem('ova_admin_token')
        }
    })
    .then(res => {
      if (res.data['code'] == 403) {
        this.$store.dispatch('clear');
        window.localStorage.removeItem('ova_admin_token')
        this.$router.push('/login');
        return;
      }
      this.companyType = [];
      for(let i in res.data.data.company_type_list){
        this.companyType.push({
          label: res.data.data.company_type_list[i].id + '. ' + res.data.data.company_type_list[i].name,
          value: res.data.data.company_type_list[i].id,
        })
      }
    })
  }
}
</script>

<style>
.edit-header {
  background: #FFFFFF;
  height: 60px;
  text-align: center;
}

.edit-header .button {
  padding-top: 10px;
  float: right;
}

.edit-header .lf-bt {
  float: left;
  padding-top: 10px;
  padding-left: 12px;
}

.edit-header .lf-bt .el-button {
  transition: .1s;
  font-weight: 500;
  padding: 12px 12px;
  font-size: 14px;
  border-radius: 4px;
}

.edit-header .state {
  line-height: 60px;
  float: right;
}

.edit-header .true {
  color: #116c01;
}

.edit-header .false {
  color: #034387;
}
</style>